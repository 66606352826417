<template>
  <div id="miles-cards-emissions" class="pd-20">
    <a-page-header
      class="mb-0 pd-0"
      sub-title="Vouchers"
      @back="() => $router.go(-1)"
    >
      <h1 slot="title">Relatório de Emissões</h1>

      <div slot="extra">
        <downloadExcel
          v-if="$root.isAdmin()"
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="excelFile.data"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block"
        >
          <img
            class="c-pointer ml-10"
            src="@/assets/images/dashboard/excel.png"
            alt="img"
          />
        </downloadExcel>
      </div>
    </a-page-header>

    <a-collapse
      class="travel-filters expandable mt-0 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <a-row class="mt-5" :gutter="20">
          <a-col :span="4">
            <div class="travel-input">
              <label for class="filled">ID Voucher</label>
              <a-input
                placeholder=""
                v-model="voucherTransactions.filters.voucherId"
                @change="getVoucherTransactions()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label for class="filled">Código Voucher</label>
              <a-input
                placeholder=""
                v-model="voucherTransactions.filters.voucherCode"
                @change="getVoucherTransactions()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label for class="filled">ID Operação</label>
              <a-input
                placeholder=""
                v-model="voucherTransactions.filters.operationId"
                @change="getVoucherTransactions()"
              />
            </div>
          </a-col>
          <a-col :span="4">
            <div class="travel-input">
              <label for class="filled">ID contrato</label>
              <a-input
                placeholder=""
                v-model="voucherTransactions.filters.contractId"
                @change="getVoucherTransactions()"
              />
            </div>
          </a-col>

          <a-col v-if="$root.isAdmin()" :span="8">
            <div class="travel-input">
              <label for class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="voucherTransactions.filters.users.selected"
                optionFilterProp="txt"
                show-search
                style="width: 100%"
                @change="getVoucherTransactions()"
              >
                <a-select-option
                  v-for="(item, index) of voucherTransactions.filters.users
                    .list"
                  :key="index"
                  :value="item.id"
                  :txt="item.first_name"
                >
                  {{ item.first_name }} {{ item.last_name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="8">
            <div class="travel-input">
              <label for class="filled">Operação</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="voucherTransactions.filters.operation.selected"
                show-search
                style="width: 100%"
                @change="getVoucherTransactions()"
              >
                <a-select-option
                  v-for="(item, index) of voucherTransactions.filters.operation
                    .list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Período</label>

              <a-range-picker
                v-model="voucherTransactions.filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getVoucherTransactions()"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <div class="travel-table">
      <HayaTable
        :data-source="voucherTransactions.list"
        :loading="voucherTransactions.loading"
        :pagination="false"
        :paginator="voucherTransactions.pagination"
        @getList="getVoucherTransactions(voucherTransactions.pagination.page)"
        @change="voucherTransactionsTableChange"
        :columns="[
          {
            title: 'ID Operação',
            dataIndex: 'voucher_transaction_id',
            key: 'voucher_transaction_id',
            class: 'voucher_transaction_id',
          },
          {
            title: 'ID Voucher',
            key: 'voucher_id',
            sorter: true,
            scopedSlots: {
              customRender: 'voucher_id',
            },
          },
          {
            title: 'ID Voyage',
            scopedSlots: {
              customRender: 'voyage_id',
            },
          },
          {
            title: 'Código Voucher',
            dataIndex: 'voucher_code',
            key: 'voucher_code',
            sorter: true,
          },

          {
            title: `Valor`,
            sorter: true,
            key: 'value',
            scopedSlots: {
              customRender: 'value',
            },
          },
          {
            title: 'Operação',
            sorter: true,
            key: 'operation',
            scopedSlots: {
              customRender: 'operation',
            },
          },
          {
            title: 'ID Contrato',
            key: 'contract_id',
            sorter: true,
            scopedSlots: {
              customRender: 'contract_id',
            },
          },
          {
            title: 'Contrante',
            scopedSlots: {
              customRender: 'contract',
            },
          },
          {
            title: '',
            dataIndex: 'user',
            key: 'user',
            width: 60,
            scopedSlots: {
              customRender: 'user',
            },
          },
          {
            title: 'Data/Hora',
            sorter: true,
            key: 'created',
            scopedSlots: {
              customRender: 'created',
            },
          },
        ]"
      >
        <div slot="total_miles" slot-scope="record">
          <div :class="record.is_canceled == '1' ? 'line-through' : ''">
            {{ formatMilesOperation(record) }}
          </div>
        </div>

        <template slot="voucher_id" slot-scope="record">
          <a class="edit" @click="edit(record.voucher_id)">
            {{ record.voucher_id }}
          </a>
        </template>

        <template slot="voyage_id" slot-scope="record">
          {{
            record.voucher.voyage_id && record.voucher.voyage_id != 0
              ? record.voucher.voyage_id
              : ""
          }}
        </template>

        <div slot="user" slot-scope="record">
          <a-tooltip>
            <template slot="title">
              {{ record.first_name }}
              {{ record.last_name }}
            </template>
            <span id="avatar">
              <a-avatar v-if="record.avatar" :src="record.avatar" :size="30" />
              <a-avatar
                v-else
                :size="30"
                style="color: #f56a00; background-color: #fde3cf"
              >
                {{ record.first_name.substring(0, 1)
                }}{{ record.last_name.substring(0, 1) }}
              </a-avatar>
            </span>
          </a-tooltip>
        </div>

        <div slot="value" slot-scope="record">
          <div :class="record.is_canceled == '1' ? 'line-through' : ''">
            {{ formatCurrency(record.currency) }} {{ record.value }}
          </div>
        </div>

        <div slot="operation" slot-scope="record">
          <span v-html="formatOperation(record.operation)" />
          <a-tooltip v-if="record.extra_info">
            <template slot="title">
              {{ record.extra_info }}
            </template>
            <a-icon
              class="ml-5 relative c-pointer"
              style="font-size: 12px; color: #aaa; top: 2px"
              type="info-circle"
            />
          </a-tooltip>
        </div>

        <div slot="contract_id" slot-scope="record">
          <div v-if="record.contract_id > 0">
            <a :href="`/contracts/edit/${record.contract_id}`" target="_blank">
              {{ record.contract_id }}
            </a>
          </div>
        </div>

        <template slot="voucher" slot-scope="record">
          {{ record.voucher_code }}
        </template>

        <template slot="contract" slot-scope="record">
          {{ record.contract.customer.first_name }}
          {{ record.contract.customer.last_name }}
        </template>

        <template slot="created" slot-scope="record">
          {{ record.created }}
        </template>
      </HayaTable>
    </div>

    <a-modal
      class="miles-card-modal"
      v-model="viewVoucher"
      width="1000px"
      :footer="false"
      @cancel="closeVoucherModal"
    >
      <VouchersModal :voucherId="selectedVoucherId" v-if="viewVoucher" />
    </a-modal>
  </div>
</template>

<script>
import HayaTable from "@/components/general/HayaTable.vue";
import VouchersModal from "@/components/vouchers/modal/VouchersModal.vue";
import formatThings from "@/mixins/general/formatThings";
import voucherTransactionsMixins from "@/mixins/vouchers-transactions/voucherTransactionsMixins.js";

export default {
  components: { HayaTable, VouchersModal },
  mixins: [voucherTransactionsMixins, formatThings],
  data() {
    return {
      viewVoucher: false,
      selectedVoucherId: 0,
      excelFile: {
        header: "RELATÓRIO DE EMISSÕES - VOUCHERS",
        fileName: "RELATÓRIO DE EMISSÕES - VOUCHERS.xls",
        collumns: {
          "ID da Operação": "voucher_transaction_id",
          "ID Voucher": "voucher_id",
          "Código Voucher": "voucher_code",
          "ID Voyage": "voyage_id",
          Moeda: "currency",
          Valor: "value",
          "ID Contrato": "contract_id",
          Operação: {
            field: "operation",
            callback: (operation) => {
              if (operation == "subtract") operation = `Emissão`;
              if (operation == "canceled") operation = `Cancelado`;
              return operation;
            },
          },
          Usuário: {
            field: "user",
            callback: (user) => {
              return `${user.first_name} ${user.last_name}`;
            },
          },
          "Data/Hora": "created",
        },
        data: [],
        footer: [],
      },
    };
  },
  beforeMount() {
    document.title = "Relatório de Emissões - HAYA";
    this.$http.get("/user/read").then(({ data }) => {
      this.voucherTransactions.filters.users.list = data;
    });
  },
  mounted() {
    this.voucherTransactions.pagination.perPage = 25;
    this.getVoucherTransactions();
  },
  methods: {
    closeVoucherModal() {
      this.selectedVoucherId = 0;
      this.getVoucherTransactions();
    },
    edit(id) {
      this.viewVoucher = true;
      this.selectedVoucherId = id;
    },
    formatOperation(operation) {
      if (operation == "subtract")
        operation = `<font class="tag return">EMISSÃO</font>`;

      if (operation == "canceled")
        operation = `<font class="tag subtract">CANCELADO</font>`;

      return operation;
    },
  },
};
</script>
